jQuery(document).ready(function() {

  $('.mandatory_doc_link').on("click", function () {  
    $(this).closest('fieldset').find('.mandatory_doc').hide(300);
    $(this).closest('fieldset').find('.answer-block').show(300);
  });

  $('.err_checkbox').on('change', function() {
    if ($(this).is(":checked")) {
      $(this).closest('fieldset').find('.answer_err_text').show(300);
    } else {
      $(this).closest('fieldset').find('.answer_err_text').hide(300);
      $(this).closest('fieldset').find('.err_text').val('');
    }
  });

  $("[id^='image_alert']").on('shown.bs.modal', function(){

    var modal_image_alert = $(this);
    var id = modal_image_alert.find('#id').val();
    var index = modal_image_alert.find('#index').val();
    var value = modal_image_alert.find('#value').val();

    var fieldset = $("#step_" + index)
    var modal_mandatory_comment = fieldset.find("#mandatory_comment_" + index);

    $(".mandatory_comment").on("click", function (e) {
      modal_mandatory_comment.find('#id').val(id);
      modal_mandatory_comment.find('#index').val(index);
      modal_mandatory_comment.find('#value').val(value);
      modal_mandatory_comment.find('#answer_type').val('BOOL');
      modal_mandatory_comment.modal("show");
    });

  });


  $("input[type=radio][id^='answer']").on("click", function () {

    var fieldset = $(this).closest('fieldset');
    var value = $(this).val();
    var mandatory_comment = fieldset.data('mandatory_comment');
    var mandatory_image = fieldset.data('mandatory_image');

    if (value == 'true' || value == 'not_relevant') {

      var id = fieldset.data('answer_id');
      var index = fieldset.data('index');

      if (mandatory_comment) {

        if (mandatory_image && $('#image_'+id)[0].files.length == 0) {

          var modal_image_alert = fieldset.find("#image_alert_" + index);
          modal_image_alert.find('#id').val(id);
          modal_image_alert.find('#index').val(index);
          modal_image_alert.find('#value').val(value);
          modal_image_alert.modal("show");

        } else {

          var modal_mandatory_comment = fieldset.find("#mandatory_comment_" + index);
          modal_mandatory_comment.find('#id').val(id);
          modal_mandatory_comment.find('#index').val(index);
          modal_mandatory_comment.find('#value').val(value);
          modal_mandatory_comment.find('#answer_type').val('BOOL');
          modal_mandatory_comment.modal("show");
        }

      } else {

        fieldset.find('.answer_err_checkbox').hide(300);
        fieldset.find('.err_checkbox').prop('checked', false)
        fieldset.find('.answer_err_text').hide(300);
        fieldset.find('.err_text').val('');

        var token = $('meta[name=csrf-token]').attr('content');

        var formData = new FormData(); 
        formData.append('index', index);
        formData.append('answer', value);
        var file_input = $('#image_'+id);
        if (file_input.length > 0) {
          var files = $('#image_'+id)[0].files;
          if (files.length > 0 ) formData.append('file', files[0]);
        }

        $.ajax({
          type: "put",
          url: "/answers/" + id,
          data: formData, //{index: index, answer: value},
          headers: {'X-CSRF-Token': token},
          enctype: 'multipart/form-data',
          processData: false,
          contentType: false,
          success: function (data) {
            $(".valid_to").text(data.valid_to);
            fieldset.replaceWith(data.html);
            if (!(data.open_index === undefined)) {
              next_step = $('#step_'+ data.open_index);
              next_step.find('h4').attr("data-toggle","collapse");
              next_step.find('.collapse').collapse('toggle');
            }
          }
        })
      }

    } else {

      fieldset.find('.answer_err_checkbox').show(300);

    }

  });

  $(".submit-answer").on("click", function (e) {
    e.preventDefault();
    e.stopPropagation();

    var fieldset = $(this).closest('fieldset');
    var token = $('meta[name=csrf-token]').attr('content');
    var id = fieldset.data('answer_id');
    var index = fieldset.data('index');
    var err_text = fieldset.find('.err_text')
    var err_reason = $(err_text).val();

    if ($(err_text).is(':visible') && $.trim(err_reason).length < 4) {

      alert(I18n.t('alerts.checklists.blank_err_reason'));

    } else {

      var formData = new FormData(); 
      formData.append('index', index);
      formData.append('answer', false);
      formData.append('err_reason', err_reason);
      var file_input = $('#image_'+id);
      if (file_input.length > 0) {
        var files = $('#image_'+id)[0].files;
        if (files.length > 0 ) formData.append('file', files[0]);
      }

      $.ajax({
        type: "put",
        url: "/answers/" + id,
        data: formData, //{index: index, answer: false, err_reason: },
        headers: {'X-CSRF-Token': token},
        enctype: 'multipart/form-data',
        processData: false,
        contentType: false,
        success: function (data) {
          $(".valid_to").text(data.valid_to);
          fieldset.replaceWith(data.html);
          if (!(data.open_index === undefined)) {
            next_step = $('#step_'+ data.open_index);
            next_step.find('h4').attr("data-toggle","collapse");
            next_step.find('.collapse').collapse('toggle');
          }
        }
      })
    }

  });

  $(".validate-answer").on("click", function (e) {
    e.preventDefault();
    e.stopPropagation();

    var token = $('meta[name=csrf-token]').attr('content');
    var fieldset = $(this).closest('fieldset');
    var id = fieldset.data('answer_id');
    var precision = fieldset.data('precision');
    var index = fieldset.data('index');
    var blank_inputs = fieldset.find('input[type=number]').filter(function() {return $(this).val() === '';}).length;
    var non_precision_inputs = fieldset.find('input[type=number]').filter(function() {return countDecimalPlaces($(this).val()) < precision;}).length;

    if (blank_inputs > 0) {
      alert(I18n.t('alerts.checklists.blank_inputs'));

    } else if (non_precision_inputs > 0) {
      alert(I18n.t('alerts.checklists.non_precision_inputs'));


    } else {

      var mandatory_comment = fieldset.data('mandatory_comment');

      if (mandatory_comment) {

        var modal_mandatory_comment = fieldset.find("#mandatory_comment_" + index);
        modal_mandatory_comment.find('#id').val(id);
        modal_mandatory_comment.find('#index').val(index);
        modal_mandatory_comment.find('#answer_type').val('NUMBER');
        modal_mandatory_comment.modal("show");

      } else {

        var values = fieldset.find('input[type=number]').map(function() {return $(this).val();}).toArray();

        $.ajax({
          type: "put",
          url: "/answers/" + id + "/validate_answer",
          data: {index: index, values: values},
          headers: {'X-CSRF-Token': token},
          success: function (data) {
            $(".valid_to").text(data.valid_to);
            fieldset.find('.validate-answer-block').hide(300);
            if (data.result) {
      
              fieldset.replaceWith(data.html);
              if (!(data.open_index === undefined)) {
                next_step = $('#step_'+ (data.open_index));
                next_step.find('h4').attr("data-toggle","collapse");
                next_step.find('.collapse').collapse('toggle');
              }
      
            } else {

              fieldset.find('.number_err').show(300);
              fieldset.find('.number_err_text').text(data.errors);

              fieldset.find('input[type=number]').prop('disabled', true);
              fieldset.find('.answer_err_checkbox').show(300);

            }
            
          }
        });

      }
    }

  });

  
  $("input[type='number']").on("keyup", function (e) {
    if ($(this).val() != '') {
      var fieldset = $(this).closest('fieldset');
      var id = fieldset.data('answer_id');
      var number_index = $(this).data('index') + 1;

      var min = +$(this).data('min');
      var max = +$(this).data('max');
      var val = +$(this).val();
      if ((!isNaN(min) && val < min) || (!isNaN(max) && val > max)) 
        $(this).addClass('is-invalid');
      else
        $(this).removeClass('is-invalid');

      elem_id = '#number_'+id+'_'+number_index;
      if ($(elem_id).length > 0) $(elem_id).prop('disabled', false);
    }
  });


  $("[id^='mandatory_comment_']").on('shown.bs.modal', function(){

    var modal_mandatory_comment = $(this);

    $(".submit-comment").on("click", function (e) {

      var comment = modal_mandatory_comment.find('#comment').val();

      if ($.trim(comment).length < 4) {
        modal_mandatory_comment.find('.alert').show(300);

      } else {
        
        var id = modal_mandatory_comment.find('#id').val();
        var index = modal_mandatory_comment.find('#index').val();
        var value = modal_mandatory_comment.find('#value').val();
        var fieldset = $("#step_" + index)
        var answer_type = modal_mandatory_comment.find('#answer_type').val();
        var token = $('meta[name=csrf-token]').attr('content');

        $('.modal.in:visible').modal('hide');
        $('.modal-backdrop').remove();
        
        if (answer_type == 'BOOL') {

          fieldset.find('.answer_err_checkbox').hide(300);
          fieldset.find('.err_checkbox').prop('checked', false)
          fieldset.find('.answer_err_text').hide(300);
          fieldset.find('.err_text').val('');

          var formData = new FormData(); 
          formData.append('index', index);
          formData.append('answer', value);
          formData.append('comment', comment);
          var file_input = $('#image_'+id);
          if (file_input.length > 0) {
            var files = $('#image_'+id)[0].files;
            if (files.length > 0 ) formData.append('file', files[0]);
          }

          $.ajax({
            type: "put",
            url: "/answers/" + id,
            data: formData, //{index: index, answer: value, comment: comment},
            headers: {'X-CSRF-Token': token},
            enctype: 'multipart/form-data',
            processData: false,
            contentType: false,
            success: function (data) {
              $(".valid_to").text(data.valid_to);
              fieldset.replaceWith(data.html);
              if (!(data.open_index === undefined)) {
                next_step = $('#step_'+ data.open_index);
                next_step.find('h4').attr("data-toggle","collapse");
                next_step.find('.collapse').collapse('toggle');
              }
            }
          });

        } else {

          var values = fieldset.find('input[type=number]').map(function() {return $(this).val();}).toArray();

          $.ajax({
            type: "put",
            url: "/answers/" + id + "/validate_answer",
            data: {index: index, values: values, comment: comment},
            headers: {'X-CSRF-Token': token},
            success: function (data) {
              $(".valid_to").text(data.valid_to);
              fieldset.find('.validate-answer-block').hide(300);
              if (data.result) {
        
                fieldset.replaceWith(data.html);
                if (!(data.open_index === undefined)) {
                  next_step = $('#step_'+ (data.open_index));
                  next_step.find('h4').attr("data-toggle","collapse");
                  next_step.find('.collapse').collapse('toggle');
                }
        
              } else {

                fieldset.find('.number_err').show(300);
                fieldset.find('.number_err_text').text(data.errors);

                fieldset.find('input[type=number]').prop('disabled', true);
                fieldset.find('.answer_err_checkbox').show(300);

              }
              
            }
          });


        }
          
      }
    });

    $(this).off('shown.bs.modal');
  });

  $('.custom-file-input').on('change',function(){
    //get the file name
    if ($(this).val() == '') {
      var fileName = '';
    } else {
      var fileName = $(this).prop("files")[0].name;
    } 
    //replace the "Choose a file" label
    $(this).next('.custom-file-label').addClass("selected").html(fileName);
  })

});


function countDecimalPlaces(number) {
  var decimalIndex = number.toString().indexOf('.');
  if (decimalIndex < 0) {
    decimalIndex = number.toString().indexOf(',');
  }
  return decimalIndex >= 0 ? number.toString().length - decimalIndex - 1 : 0;
}